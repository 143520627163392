<template>
  <div class="controllerQidao">
    <div class="controller-modules-header left">气道动作</div>
    <div class="radio-controller">
      <div
        class="item"
        v-for="(item, index) in qidaoList"
        :key="index"
        :class="qidao == item.type || qidao == item.type_1 ? 'item-active' : ''"
      >
        <div
          @click="
            handlerRadio(
              'qidao',
              qidaoHuanzhuangruangu ? item.type_1 : item.type,
              item.state
            )
          "
          v-if="item.state && item.state == 'qidao_huanzhuangruangu'"
        >
          {{ qidaoHuanzhuangruangu ? item.name_1 : item.name }}
        </div>
        <div
          @click="
            handlerRadio(
              'qidao',
              qidaoYangqimianzhao ? item.type_1 : item.type,
              item.state
            )
          "
          v-if="item.state && item.state == 'qidao_yangqimianzhao'"
        >
          {{ qidaoYangqimianzhao ? item.name_1 : item.name }}
        </div>
        <div
          @click="
            handlerRadio(
              'qidao',
              qidaoLma ? item.type_1 : item.type,
              item.state
            )
          "
          v-if="item.state && item.state == 'qidao_lma'"
        >
          {{ qidaoLma ? item.name_1 : item.name }}
        </div>
        <div @click="handlerRadio('qidao', item.type)" v-if="!item.state">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="controller-modules-header left">插管</div>
    <div class="radio-controller2 float">
      <div class="radio-controller">
        <div
          class="item"
          v-for="(item, index) in chaguanList"
          :key="index"
          :class="chaguan == item.type ? 'item-active' : ''"
          @click="handlerRadio('chaguan', item.type)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 喉镜 -->
      <div class="radio-controller2-info" v-show="houjing">
        <div class="header float">
          深度
          <a-select
            v-model:value="selectValue"
            placeholder="请选择"
            @change="selectChange"
          >
            <template #suffixIcon>
              <img
                class="select_img"
                src="@/assets/images/ico_select.png"
                alt=""
              />
            </template>
            <a-select-option
              :value="item"
              v-for="(item, index) in shenduList"
              :key="index"
              >{{ item }}</a-select-option
            >
          </a-select>
        </div>
        <p class="detail">管深度为{{ selectValue || 0 }}cm</p>
        <button class="controller-btn" @click="handleHeight('laryngoscopy')">
          取出管
        </button>
      </div>
      <!-- 光纤喉镜 -->
      <div class="radio-controller2-info" v-show="guangxianhoujing">
        <div class="header float">
          深度
          <a-select
            v-model:value="selectValue"
            placeholder="请选择"
            @change="selectChange"
          >
            <template #suffixIcon>
              <img
                class="select_img"
                src="@/assets/images/ico_select.png"
                alt=""
              />
            </template>
            <a-select-option
              :value="item"
              v-for="(item, index) in shenduList"
              :key="index"
              >{{ item }}</a-select-option
            >
          </a-select>
        </div>
        <p class="detail">管深度为{{ selectValue || 0 }}cm</p>
        <button class="controller-btn" @click="handleHeight('fiberoptic')">
          取出管
        </button>
      </div>
    </div>
    <!-- 喉镜 -->
    <div
      class="radio-controller2-detail float"
      v-show="tubeController == 'laryngoscopy'"
    >
      <img src="@/assets/images/hj.png" alt="暂无图片" />
      <div class="info">
        <!-- <h4>喉镜-隆突清晰可见，超出管的尖端</h4> -->
         <h4>喉镜的1级视图</h4>
        <div class="controller-btn-view float">
          <button @click="getOut('laryngoscopy')">放置管</button>
          <button @click="layUp">不要放置管</button>
        </div>
      </div>
    </div>
    <!-- 光纤喉镜 -->
    <div
      class="radio-controller2-detail float"
      v-show="tubeController == 'fiberoptic'"
    >
      <img src="@/assets/images/gq.png" alt="暂无图片" />
      <div class="info">
        <h4>你通过了声带，可以清楚地看到隆突。</h4>
        <div class="controller-btn-view float">
          <button @click="getOut('fiberoptic')">放置管</button>
          <button @click="layUp">不要放置管</button>
        </div>
      </div>
    </div>
    <div
      class="radio-controller2-detail float"
      v-show="tubeController == 'crico'"
    >
      <img src="" alt="暂无图片" />
      <div class="info">
        <h4>
          您成功地进行了环甲切开术。通过环甲膜插入的 12 号静脉导管吸入空气。
        </h4>
        <div class="controller-btn-view float">
          <button @click="getOut('crico')">通过导管通风</button>
          <button @click="handleHeight('crico')">移除导管</button>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 16px"></div>
    <div class="controller-modules-header left">操作记录</div>
    <div class="controller-modules-log">
      <block v-show="actionLog.length != 0">
        <div class="item" v-for="(item, index) in actionLog" :key="index">
        【{{formateSeconds(item.logTime)}}】{{item.logContent}}
        </div>
      </block>
      <block v-show="actionLog.length == 0">
        <div class="item" >
            暂无操作记录。
        </div>
      </block>
    </div>
  </div>
</template>
<script>
import { qidaoList, chaguanList, shenduList } from "@/utils/data";
import { mapGetters, mapActions } from "vuex";
import  {formateSeconds} from '@/utils/tool'
export default {
  data() {
    return {
      qidaoList,
      chaguanList,
      shenduList,
      qidao: "",
      chaguan: "",
      selectValue: "22",
      tubeController: "",
      tubePut: false,
      formateSeconds,
      actionLog:[],
    };
  },
  created(){
    this.getActionLog();
  },
  methods: {
    ...mapActions({
      submitStep: "exma/submitStep",
      setQidao: "controller/setQidao",
      resetYangqi: "controller/resetYangqi",
    }),
    selectChange(value) {
      if(value == 22)return;
      let d = {
        memberCaseId: this.memberCaseId,
        actionTime: this.getHandleTimeAt,
        component: "reduce_tube_depth",
        otherData: {
          shendu: value,
        },
      };
       this.submitStep(d).then(res=>{
        this.getActionLog();
      });
    },
    //放置管
    getOut(type) {
      if ((this.houjing || this.guangxianhoujing) && type != 'crico') {
        this.$notification.error({
          message: "警告",
          description: `请先取出管`,
          duration: null,
        });
        return;
      }
      let _type = this.chaguanList.find((item) => item.type == type);
      let d = {
        memberCaseId: this.memberCaseId,
        actionTime: this.getHandleTimeAt,
        component: _type.place,
      };
      this.submitStep(d).then(res=>{
        this.getActionLog();
        if(res == 1){
          this.resetYangqi();
          this.tubeController = "";
          if (type != "crico") {
            this.setQidao(type == "laryngoscopy" ? "houjing" : "guangxianhoujing");
            return;
          }
          
        }
      });
      
    },
    //不要放置
    layUp() {
      this.tubeController = "";
    },
    //深度 取出管
    handleHeight(type) {
      let _type = this.chaguanList.find((item) => item.type == type);
      let d = {
        memberCaseId: this.memberCaseId,
        actionTime: this.getHandleTimeAt,
        component: _type.remove,
      };
      this.submitStep(d).then(res=>{
        this.getActionLog();
        if(res == 1){
          if (type != "crico") {
            this.setQidao(type == "laryngoscopy" ? "houjing" : "guangxianhoujing");
            return;
          }else{
            this.tubeController = "";
          }
        }
      });
      
    },
    handlerRadio(model, type, state) {
      this[model] = type || "";
      if (model == "qidao") {
        let d = {
          memberCaseId: this.memberCaseId,
          actionTime: this.getHandleTimeAt,
          component: type,
        };
        this.submitStep(d).then(res=>{
          this.getActionLog();
          if(res == 1){
            if (state) this.setQidao(state);
            if(state == 'qidao_lma'){
              this.resetYangqi();
            }
          }
        });
      } else {
        if (this.tubeController == type) return;
        this.tubeController = type;
      }
    },
    submit() {
      if (!this.selectValue) return;
      let d = {
        memberCaseId: this.memberCaseId,
        actionTime: this.getHandleTimeAt,
        component: "drug",
        drugId: this.selectValue,
        infusion: this.selectValue2,
        bolus: this.selectValue1,
      };
      this.submitStep(d).then(res=>{
        this.getActionLog();
      });
    },
    async getActionLog(){
      try{
        let res = await this.$http.post(this.$interface.examActionLog,{
          componentCategory:'airway',
          memberCaseId:this.memberCaseId,
        })
        this.actionLog = res.data.data || [];
      }catch(err){}
    }
  },
  computed: {
    ...mapGetters([
      "getHandleTimeAt",
      "memberCaseId",
      "qidaoHuanzhuangruangu",
      "qidaoYangqimianzhao",
      "qidaoLma",
      "houjing",
      "guangxianhoujing",
    ]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>